<template>
  <div
    :class="`card no-gutter widget-overview-box widget-overview-box-${number}`"
  >
    <span class="overview-icon">
      <i :class="`${icon}`"></i>
    </span>
    <span class="overview-title">{{ title }}</span>
    <div class="p-grid overview-detail">
      <template v-if="value3 !== undefined">
        <div class="p-col-4">
          <div class="overview-number">
            <NumberFormatter :value="value1" />
          </div>
          <div class="overview-subtext">
            {{ text1 }}
          </div>
        </div>
        <div class="p-col-4">
          <div class="overview-number">
            <NumberFormatter :value="value2" />
          </div>
          <div class="overview-subtext">
            {{ text2 }}
          </div>
        </div>
        <div class="p-col-4">
          <div class="overview-number">
            <NumberFormatter :value="value3" />
          </div>
          <div class="overview-subtext">
            {{ text3 }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="p-col-6">
          <div class="overview-number">
            <NumberFormatter :value="value1" />
          </div>
          <div class="overview-subtext">
            {{ text1 }}
          </div>
        </div>
        <div class="p-col-6">
          <div class="overview-number">
            <NumberFormatter :value="value2" />
          </div>
          <div class="overview-subtext">
            {{ text2 }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: [
    "icon",
    "title",
    "value1",
    "text1",
    "value2",
    "text2",
    "number",
    "text3",
    "value3",
  ],
};
</script>

<style scoped></style>
