import { MonthlyRevenueOutput } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useLazyQuery } from "@vue/apollo-composable";
import { reactive } from "vue";
import { useI18n } from "vue-i18n";

type CurrentYearRevenueByMonth = {
  currentYearRevenueByMonth: MonthlyRevenueOutput[];
};

const QUERY = gql`
  query CurrentYearRevenueByMonth {
    currentYearRevenueByMonth {
      month
      amount
    }
  }
`;

export const useCurrentYearRevenueByMonth = () => {
  const { tm } = useI18n();
  const chartData = reactive({
    labels: tm("prime.monthNamesShort") as string[],
    datasets: [
      {
        label: tm("ca"),
        data: Array(12).fill(0),
        fill: true,
        backgroundColor: "#FFA726",
        borderWidth: 1,
        borderColor: "#FFA726",
        tension: 0.4,
      },
    ],
  });

  const { loading, load, onResult } = useLazyQuery<CurrentYearRevenueByMonth>(
    QUERY,
    { fetchPolicy: "no-cache" }
  );
  onResult(({ data }) => {
    if (data) {
      for (let month = 1; month <= 12; month++) {
        chartData.datasets[0].data[month - 1] =
          data.currentYearRevenueByMonth.find((s) => s.month === month)
            ?.amount || 0;
      }
    }
  });
  function initChart() {
    void load();
  }
  return { loading, chartData, initChart };
};
