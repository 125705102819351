import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";

type AdminOverviewsData = {
  subscriptionPaymentTotalAmount: number;
  usersCount: number;
  activitiesCount: number;
};

const ADMIN_OVERVIEWS = gql`
  query AdminOverviews {
    subscriptionPaymentTotalAmount
    usersCount
    activitiesCount
  }
`;

export const useAdminOverviews = () => {
  const { loading, result } = useQuery<AdminOverviewsData>(ADMIN_OVERVIEWS);
  const overviews = useResult<
    AdminOverviewsData,
    AdminOverviewsData,
    AdminOverviewsData
  >(
    result,
    {
      subscriptionPaymentTotalAmount: 0,
      usersCount: 0,
      activitiesCount: 0,
    },
    (res) => res
  );
  return { loading, overviews };
};
