
import { defineComponent } from "vue";
import Alert from "@/components/common/Alert.vue";
import { featureIcons } from "@/layouts/workspace/menu-settings";
import { useAdminOverviews } from "@/graphql/dashboard/admin-overviews";
import CurrentRevenueBySubscription from "@/components/dashboad/CurrentRevenueBySubscription.vue";
import { useCurrentYearRevenueByMonth } from "@/graphql/subscription/current-year-revenue-by-month";
export default defineComponent({
  name: "Dashboard",
  components: { Alert, CurrentRevenueBySubscription },
  setup() {
    return {
      featureIcons,
      ...useAdminOverviews(),
      ...useCurrentYearRevenueByMonth(),
      options: {
        plugins: {
          legend: {
            display: false,
            labels: {
              color: "white",
            },
          },
        },
        responsive: true,
      },
    };
  },
  created() {
    this.initChart();
  },
});
