import { CurrentRevenueBySubscription } from "@/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";

type CurrentRevenueBySubscriptionData = {
  currentRevenueBySubscription: CurrentRevenueBySubscription[];
};

const QUERY = gql`
  query CurrentRevenueBySubscription {
    currentRevenueBySubscription {
      subscriptionId
      activityCount
      amountSum
    }
  }
`;

export const useCurrentRevenueBySubscription = () => {
  const { loading, result } = useQuery<CurrentRevenueBySubscriptionData>(QUERY);
  const revenueBySubscription = useResult(
    result,
    [],
    (res) => res.currentRevenueBySubscription
  );
  return {
    loading,
    revenueBySubscription,
  };
};
