
import { defineComponent } from "vue";
import { useCurrentRevenueBySubscription } from "@/graphql/subscription/current-revenue-by-subscription";
export default defineComponent({
  name: "CurrentRevenueBySubscription",
  setup() {
    return {
      ...useCurrentRevenueBySubscription(),
    };
  },
});
